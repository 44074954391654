import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/_2vxybW1xRs"
    bibleGroupSrc="https://www.docdroid.net/vK1zlRf/bible-group-homework-7-5-pdf"
    bibleGroupText="Bible Group Homework"
    notesSrc="https://www.bible.com/events/22805114"
    notesText="Sermon Notes"
  >
    <SEO title="Chist-Centered Family - Colossians" />
  </Layout>
)

export default SermonPost
